<template>
  <div class="step-content">
    <completion-progress
      :completion="completion"
      class="pa-4"
    />
    <v-card-actions 
      class="cta"
    >
      <v-btn 
        large
        block
        color="accent"
        class="btn-cta grey--text text--darken-4"
        @click="actionCalled('step'+step.index)"
      >
        {{ step.cta.text }}
      </v-btn>
    </v-card-actions>

    <v-btn 
      text
      small
      class="help-btn mt-10"
      color="grey"
      @click="toggleHelp(true, 'signup')"
    >
      <v-icon
        small
        class="mr-4"
      >
        {{ icons.mdiHelpCircleOutline }}
      </v-icon>
      Precisa de ajuda?
    </v-btn>

    <v-dialog
      v-model="confirm.toggle"
      overlay-opacity=".8"
      transition="slide-y-reverse-transition"
    >
      <v-card color="black">
        <v-card-title>Confirmar envio?</v-card-title>
        <v-card-text class="py-4">
          <p class="text-body-1">
            Ao enviar seus dados para avaliação, você garante que as informações são corretas e verídicas.
            <br>
            <b>Atenção:</b> Informações falsas podem resultar em bloqueio da conta e exclusão do processo seletivo. 
            <br>
            Confirmar envio dos dados?
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            text
            :disabled="confirm.loading"
            @click="toggleConfirm(false);"
          >
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="confirm.loading"
            @click="requestEvaluation"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>

</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiClose,
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';


  export default {
    name: 'Step1',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      appURL: {
        type: String,
        default: ''
      }
    },

    components: {
      CompletionProgress: () => import('@/components/CompletionProgress'),
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
      },
      confirm: {
        toggle: false,
        loading: false
      },
      btnSend: {
        loading: false,
        disabled: false,
      }
    }),

    computed: {
      user: sync('user'),
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),

      completion () {
        return _.round(this.step.completion * 100);
      }
    },

    watch: {
      completion: { 
        immediate: true,
        handler (v) {
          if (v==100) {
            this.step.cta.text = 'Enviar meus dados';
          }else{
            this.step.cta.text = 'Completar perfil';
          }
        }
      }
    },

    methods: {
      ...services,

      actionCalled () {
        if (this.completion==100) {
          this.toggleConfirm(true);
        }else{
          this.toggleView('profile');
        }
      },
      
      toggleConfirm (b) {
        this.confirm.toggle = b;
      },

      requestEvaluation () {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);
        this.confirm.loading = true;

        this.$api({
          url: '/requestevaluationdriver',
            method: 'POST',
            data: {
              cpf: cpf,
              authTk: token,
            }
          })
          .then(response => {
            console.log('requestEvaluationDriver => ',response);
            if(response.data.retorno==200){
              this.toggleConfirm(false);
              const status = _.has(response.data, 'status_motorista') ? response.data.status_motorista : 'FDB';
              this.status = status;
              // this.statusChange = moment().valueOf();

              this.$emit('next', {
                status,
                info: {
                  evaluation: {
                    score: response.data.score,
                    feedbacks: response.data.feedbacks,
                  }
                }
              });
              this.toggleView();

              this.trackEvent('signup/step1', 'request evaluation ok');
            }else{
              this.toggleToast(
                true,
                'Não foi possível carregar sua avaliação.',
                5000,
                false,
              );
              this.trackEvent('signup/step1', 'request evaluation failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível carregar sua avaliação. Verifique sua conexão e tente novamente, por favor.', true);
          })
          .finally(() => {
            this.confirm.loading = false;
          });
      },

    },

    created () {
    },

    mounted () {
    },

    directives: {
    },

  }
</script>
